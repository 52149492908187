import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useBarcode } from 'react-barcodes';

import { cxcService } from '../../Services/cxcService';

import { Common } from '../../Utils/Common';

import swal from 'sweetalert';
import {v4 as uuidv4} from 'uuid';
import tapiCommerces from '../PulledApart/tapiCommerces';
import { tapiUserService } from '../../Services/Fintechs/tapiUserService';
import { tapiService } from '../../Services/Fintechs/tapiService';
import ReactLoading from 'react-loading';
const OnSitePaymentByTapi = () => {
    const user = Common.getUser();
    const { id, cxcid } = useParams();
    const [referenceExpirationDate, setReferenceExpirationDate] = useState("");
    const [loading, setLoading] = useState(true);
    const [finalReference, setFinalReference] = useState("");
    const [stateNextPayment, setStateNextPayment] = useState({
        fechaVencimiento: new Date, id: 0, identificador: 0, notasCxc: "",
        numfactura: "0", pagos: 0, saldo: 0, valor: 0,
        ventaId: 0,
        visibleAlPago: false
    });

    const { inputRef } = useBarcode({
        value: finalReference == '' ? 0 : finalReference,
        options: {
          background: '#FD9913',
          width: 1,
          height: 50
        }
    });

    useEffect(() => {
        cxcService.getByIdWithBalance(cxcid).then(res => {
            setStateNextPayment(res.data);
        });
        onClickBtnforCreatePullapartMethod()
    }, []);

    const GetTokenFromApFintechs = async () => {
        const userForAuthenticate = {
            "Id": 0,
            "User": "soporte@axify.mx",
            "Password": "Axify2023",
            "Token": ""
        };
        const responseTapiUser = await tapiUserService.Authentication(userForAuthenticate);
        const tokenclib = responseTapiUser.data.data.token;

        return tokenclib;
    }

    const onClickBtnforCreatePullapartMethod = async () => {
        if (!id) {
            swal("Sucedió un error", "No se incluyó una referencia", "warning")
                .then((value) => {
                    navigate(`/`, { replace: true });
                });
            return
        }

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 2);
        const formattedDate = tomorrow.toISOString().split('T')[0];
        const tokenTapiFintech = await GetTokenFromApFintechs();
        let externalUUID = await uuidv4()
        const payload = {
            externalRequestId: externalUUID,
            companyCode: "MX-S-00315",
            externalClientId: id.toString(),
            amountType: "OPEN",
            amount: stateNextPayment.valor + 10,
            currency: "MXN",
            generationData: [
                {
                    modalityId: "76a1a3f8-dca5-43ae-9d07-df3c8467582e",
                    identifierName: "ARXG-INV",
                    identifierValue: "null"
                },
                {
                    modalityId: "e8ba4e43-a010-44c9-8888-129fdde72103",
                    identifierName: "ARXF-INV",
                    identifierValue: "123456"
                }
            ],
            additionalData: [
                {
                    dataName: "email",
                    dataValue: user.user
                },
                {
                    dataName: "debtExpirationDate",
                    dataValue: formattedDate
                }
            ]
        };

        //console.log(JSON.stringify(payload));

        const responsePaymentMethod = await tapiService.generateReference(tokenTapiFintech, payload);
        if (!responsePaymentMethod.isSuccess) {
            swal("Sucedió un error", "No pudimos generar tu referencia", "warning")
                .then((value) => {
                    navigate(`/`, { replace: true });
                });
        } else {
            if (responsePaymentMethod.data.references?.length > 0) {
                setFinalReference(responsePaymentMethod.data.references[0].value)
                setReferenceExpirationDate(formattedDate)
            } else {
                swal("Error al crear la referencia", "Sucedió un error al crear la referencia", "warning")
            }
        }
        setLoading(false)

    }

    return (
        <>
            <div className="container">
                <div className='row'>
                    <div className='col'></div>
                    <div className='col' style={{ textAlign: 'center' }}>
                        <h3>Pago de manera presencial</h3>
                    </div>
                    <div className='col'></div>
                </div>
                <div className="row">
                    <div className="col">
                        {loading ?
                            <div className='alert alert-success d-flex justify-content-center' role="alert" >
                                <ReactLoading type={"spin"} color={"black"} height={100} width={100} />
                            </div> :
                            <div className='alert alert-success' role="alert" style={{ textAlign: 'center' }}>
                                <h4>¿Cómo puedes pagar?</h4>
                                <p>Ve de manera presencial a cualquiera de los establecimientos y pide hacer un pago referenciado</p>
                                <p>Vencimiento de pago: {moment(stateNextPayment.fechaVencimiento).format('MMMM DD YYYY')}</p>
                                <p>Valor: {Common.currencyFormat(stateNextPayment.valor)}</p>
                                <p>Pagos: {Common.currencyFormat(stateNextPayment.pagos)}</p>
                                <p>Saldo: {Common.currencyFormat(stateNextPayment.saldo)}</p>
                                <p>Transacción: $10.00</p>
                                <p>Referencia: {finalReference} (Expira el {referenceExpirationDate})</p>
                                <div className='row'>
                                <div className='col' style={{textAlign:'center', fontSize:'14px'}}>
                                        <svg ref={inputRef} />
                                    </div>                        
                                </div> 
                            </div>
                        }
                    </div>
                </div>                
                <div className='row'>
                    {tapiCommerces.map((item) => {
                        return (
                            <div className="col-lg-4 col-sm-4 col-xs-6 d-flex justify-content-center align-items-center" key={item.name}>
                                <img src={item.logo} className='responsiveImageByWidth' />
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}

export default OnSitePaymentByTapi;