import React, { useState } from 'react';

import swal from 'sweetalert';

import { salesService } from '../../Services/salesService';

import { Common }from '../../Utils/Common'

import "./popupcancelSale.css";

const PopUpCancelSale = (props) => {   
    const token = Common.getToken();    
    const user = Common.getUser();  

    const [stateSale, setStateSale] = useState({
        id : props.saleId,
        businessId: 0,
        saleDate: new Date(),
        description: '',
        details: '',
        clientId:0,
        productId:0,
        sku:'',
        ammount: 0,
        ammountPre: 0,
        limitDatePay: new Date(),
        vigencyDateToLC: new Date(),
        companyId: 0,
        apartReference: 0,
        creationDate: new Date(),
        updateDate: new Date(),
        SSOId: 0,
        saleStatusId: 2,
        cancelDate: new Date(),
        cancelNotes: '',
        SSOIdBuyerCancel: user.id,
        SSOIdBussineeCancel: 0,
        numNotificationCancel: 0
    });   
    
    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateSale((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const onClickDomEventbtnClosefrompopup = (e) => {        
        //console.log(token);
        //console.log(JSON.stringify(stateSale));
        
        salesService.put(token, props.saleId, stateSale);
        props.togglePop();

        swal("Cancelación exitosa!", "Se cancelo el apartado seleccionado!", "success")
        .then((value) => {
            props.ExecuteGetPullApart();
        });        
    }  

    return (
        <div className='popup'>           
            <div className='popup_inner'>
                <div className="popup_header">  
                    ¿Estas seguro de cancelar tu compra?
                </div>
                <div className="popup_body">                                                      
                    <br></br>
                    <br></br>                    
                    <div className='row'>
                        <div className='col' style={{textAlign:'center'}}>
                            <div className="mb-3">
                                <label htmlFor="cancelNotes" className="form-label">Favor de escribir el motivo de cancelaciòn</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="cancelNotes" 
                                    name="cancelNotes" 
                                    placeholder="" 
                                    onChange = {onChangehandleChange}
                                    value={stateSale.cancelNotes}  
                                    />
                            </div>
                        </div>                        
                    </div>                        
                </div>
                <div className="sectionBtnClose">
                    <button className="btn btn-warning" onClick={onClickDomEventbtnClosefrompopup}>Cancelar  Venta</button>
                </div>
            </div>
        </div>         
    )
}

export default PopUpCancelSale;