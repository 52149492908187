import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { cxcService } from '../../Services/cxcService';
import { boromirUserService } from '../../Services/Boromir/boromirUserService';
import { boromirService } from '../../Services/Boromir/boromirService';
import { clibUserService } from '../../Services/Fintechs/clibUserService';
import { clibService } from '../../Services/Fintechs/clibService';

//import {v4 as uuidv4} from 'uuid';
import swal from 'sweetalert';

import { Common } from '../../Utils/Common'

const ThreeDSByClipPA = () => {
    const { id, cxcid, bussinessid, saldo } = useParams();

    const ref = useRef(null);
  
    const [statepending_action_url, setstatepending_action_url] = useState('');

    const [stateCurrentPayment, setStateCurrentPayment] = useState({
        fechaVencimiento: new Date, id: 0, identificador: 0,notasCxc: "",
        numfactura: "0",pagos: 0,saldo: 0,valor: 0,
        ventaId: 0,
        visibleAlPago: false
            });

    const Handle3dsResponseMessage = (event) => {
        //console.log(event);

        if (event.origin !== "https://3ds.payclip.com") {
            return; // Ignorar mensajes de otros orígenes""
        } else {
            if (event.data?.paymentId) {
                const returnedPaymentId = event.data.paymentId;
                // console.log("Returned Payment ID:", returnedPaymentId);
                // console.log("Axify Id:", id);
                // console.log("Axify Idcxc:", cxcid);
                ApplyPaymentMethod(event.data.paymentId, id, cxcid, bussinessid, saldo);
            } 
        }
    }
    
    useEffect(() => {
        cxcService.getByIdWithBalance(cxcid).then( res => {                
            //console.log(res);
            setStateCurrentPayment(res.data);         
        });        

        SetLocalstorageVariables();       
    
        window.addEventListener('message', Handle3dsResponseMessage);

        // cleanup this component
        return () => {
            window.removeEventListener('message', Handle3dsResponseMessage);
        };
    }, []);

   const SetLocalstorageVariables = () => {
        setstatepending_action_url(localStorage.getItem("pending_action.url"));
   }

    const ApplyPaymentMethod = async (paymentIdClipP, idP, cxcIdP, bussinessidP, saldo) => {        
        console.log('inicio','proceso de aplicacion de pago hacia boromir');

        const tokenClibfintech = await GetTokenFromApFintechs();
        const responsePaymentMethod = await clibService.getPaymentsAsync(tokenClibfintech, paymentIdClipP, bussinessidP);

        //console.log(responsePaymentMethod);

        if(responsePaymentMethod.data.status == 'approved' && responsePaymentMethod.data.status_detail.code == 'AP-PAI01') {
            const userForAuthenticate = {
                "User": "soporte@axify.mx",
                "Password": "Axify2023"
            };

            let responseUserBoromir = await boromirUserService.postAuthenticate(userForAuthenticate);

            const tokentoboromir = responseUserBoromir.token;
            //console.log(tokentoboromir);

            const requestBoromirPayment = {
                "referencia": idP,
                "importe": saldo,
                "compañia": '0',
                "sucursal": bussinessidP.toString(),
                "idtransaccion" : 'Clip|' + paymentIdClipP
            };

            //console.log(JSON.stringify(requestBoromirPayment));

            let responseBoromirPaymentService = await boromirService.postProductPayment(tokentoboromir, requestBoromirPayment);
                    
            //console.log(responseBoromirPaymentService);

            if (responseBoromirPaymentService.error == 0){
                swal("Transacción realizada!", "Gracias por su pago!", "success")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    window.location.href = '/';                    
                });
    
            } else {
                swal("Abono no aplicado en axify!", "favor de contactar a soporte de axify!", "warning")
                .then((value) => {
                    CleanClipLocalStorageInfo();                    
                    window.location.href = '/';                    
                });    
            }

        } else {
            swal("Validación rechazada!", "Favor de reintentar el pago!", "warning")
            .then((value) => {
                CleanClipLocalStorageInfo();
                window.location.href = '/';                
            });
        }
    }    

    const GetTokenFromApFintechs = async () => {
        const userForAuthenticate = {
            "Id": 0,
            "User": "soporte@axify.mx",
            "Password": "Axify2023",
            "Token": ""
        };
        const responseclibUser = await clibUserService.Authentication(userForAuthenticate);
        
        //console.log(responseclibUser);
        
        const tokenclib = responseclibUser.data.data.token;

        return tokenclib;
    }  

    const CleanClipLocalStorageInfo = () => {
        localStorage.setItem("Publickey", '');
        localStorage.setItem("cardTokenClib", '');
        localStorage.setItem("pending_action.url", '');
    }

    

    return (
       <>
        <div className="container">            
            <div className='row'>
                <div className='col'></div>
                <div className='col' style={{textAlign: 'center'}}>
                    <h3>Autenticación 3DS</h3>
                </div>
                <div className='col'></div>
            </div>
            <div className="row">
                <div className="col"></div>
                <div className="col">
                    <div className='alert alert-success' role="alert" style={{textAlign: 'center'}}>                 
                            <p>Vencimiento: {moment(stateCurrentPayment.fechaVencimiento).format('MMMM DD YYYY')}</p>
                            <p>Valor: {Common.currencyFormat(stateCurrentPayment.valor)}</p>
                            <p>Pagos: {Common.currencyFormat(stateCurrentPayment.pagos)}</p>
                            <p>Saldo: {Common.currencyFormat(stateCurrentPayment.saldo)}</p>                           
                    </div>
                </div>
                <div className="col"></div>
            </div>        
            <div className='row'>
                <div className='col'></div>
                <div className='col'>
                    <legend><img src="/assets/img/cliblogo.jpg" alt="Logo Clib" style={{ "width": "100px", "padding": "2px", "marginLeft": "40%" }}/></legend>                    
                </div>
                <div className='col'></div>
            </div>
            <div className='row'>
                <div className='col'></div>
                <div className='col'>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            {/* <iframe title="3dsClibV1" src="/3dsClibV2.html" style={{border:"none", width:"500px", height:"400px"}}></iframe> */}
                            <iframe ref={ref}
                                title="cybersource3Ds"
                                src={statepending_action_url}
                                data-testid="cybersource3Ds-iframe"
                                style={{"width": "500px","height": "400px"}}
                            />       
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className='col'></div>
            </div>            
        </div>  
       </>         
    )

}

export default ThreeDSByClipPA;